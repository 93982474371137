/*
    Automatically check the checkbox based on a previously visited page.

    Enter in the page administration:

    +--------------------------------+
    | Style                          |
    +--------------------------------+-------------------------------------------------------+
    |  Advanced settings:                                                                    |
    |  ATTRIBUTES:                                                                           |
    |  +--------------------+    +---------------------------------------------------------+ |
    |  | data-checkbox_keys |    | dns-statistics,dns-anycast,domain-registry,dns-software | |
    |  +--------------------+    +---------------------------------------------------------+ |
    +--------------------------------+-------------------------------------------------------+
        +--------------------------------+
        | Multiple Checkbox Select Field |
        +--------------------------------+
*/
class SelectCheckbox {
    constructor(element, referrer) {
        // element: <div ...> of plugin "Multiple Checkbox Select Field".
        this.inputs = element.querySelectorAll('input[type=checkbox]')
        this.partUrl = []
        if (element.dataset.checkbox_keys) {
            this.partUrl = element.dataset.checkbox_keys.split(',').map(i => i.trim())
        }
        if (this.partUrl && referrer) {
            const url = new URL(referrer)
            const match = url.pathname.match(/([^/]+)\/?$/)
            if (match) {
                const index = this.partUrl.indexOf(match[1])
                if (index !== -1) {
                    this.inputs[index].checked = true
                }
            }
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    for (const element of document.getElementsByClassName('checkbox-url')) {
        new SelectCheckbox(element, document.referrer)
    }
})
