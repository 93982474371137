class requiredCheckbox {
    constructor(checkboxset) {
        this.checkboxset = checkboxset
        this.dangerClass = checkboxset.querySelector('.text-danger')
        checkboxset.addEventListener('input', () => {
            const chosen = checkboxset.querySelectorAll('input[type=checkbox]:checked').length
            const checkboxes = checkboxset.querySelectorAll('input[type=checkbox]')

            if (chosen < checkboxset.dataset.required_min) {
                for (const input of checkboxes) {
                    input.classList.remove('checkbox-ok')
                    this.dangerClass.classList.remove('d-none')
                }
            } else {
                for (const input of checkboxes) {
                    input.classList.add('checkbox-ok')
                    this.dangerClass.classList.add('d-none')
                }
            }
        })
    }
}

document.addEventListener('DOMContentLoaded', () => {
    for (const checkboxset of document.querySelectorAll('.base-form .checkbox.has-error')) {
        new requiredCheckbox(checkboxset)
    }
})
