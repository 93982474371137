export function setCMSBarWidth() {
    const container_class_name = "set-bar-width"
    const bar = document.querySelector(".cms-dragbar-title")
    if (bar && !document.getElementsByClassName(container_class_name).length) {
        const container = document.createElement("span")
        container.classList.add(container_class_name)
        const widths = [[800, "the largest"], [600, "larger"], [416, "default"]]
        for (const i in widths) {
            if (i > 0) {
                container.appendChild(document.createTextNode(", "))
            }
            const width = widths[i][0]
            const label = widths[i][1]
            const link = document.createElement("a")
            link.appendChild(document.createTextNode(label))
            link.href = `#${width}`
            link.addEventListener("click", (event) => {
                event.stopPropagation()
                document.querySelector("div.cms .cms-structure.cms-structure-condensed").style.width = `${width}px`
                return false
            })
            container.appendChild(link)
        }
        bar.appendChild(container)
    }
}
