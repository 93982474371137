import { setCMSBarWidth } from './cms-bar-width'


/* global gettext */

import './select_checkbox'
import './required_checkbox'
import { commercialTable } from './commercial_table'

document.addEventListener('DOMContentLoaded', () => {
    /* Menu control */
    const body = document.getElementsByTagName("BODY")[0]
    const toggleMenu = document.getElementById('toggleMenu')

    function menu(element, add) {
        toggleMenu.addEventListener('click', function() {
            element.classList.toggle(add)
        })
    }

    menu(body, 'show-body')

    // Because message 'Enter a valid email address.' is hidden.
    for (const email of document.querySelectorAll('.base-form input[type=email].has-error')) {
        if (email.value) {
            email.classList.add('email-error')
        }

        email.addEventListener('input', () => {
            email.classList.remove('email-error')
        })
    }

    for (const svg of document.querySelectorAll('.cooperation aside img')) {
        svg.insertAdjacentHTML('afterend', `<div class="info-tooltip" >${svg.alt}</div>`)
    }

    // Open according collapse
    for (const collapse of document.querySelectorAll('.collapse-box')) {
        if (collapse.querySelector('*[id*="open"') && !window.location.hash) {
            collapse.querySelector('header[id^=trigger-open]').classList.remove('collapsed')
            collapse.querySelector('header[id^=trigger-open]').setAttribute('aria-expanded', true)
            collapse.querySelector('aside[id^=open]').classList.add('collapse', 'show')
        }
    }

    for (const item of document.querySelectorAll('.prevent-default')) {
        item.href = ''
    }

    // Add subject for link with mailto
    for (const link of document.querySelectorAll('a[href^="mailto"]')) {
        if (link.dataset.subject) {
            link.href = `${link.href}?subject=${link.dataset.subject}`
        }
    }

    // Open first nav tab
    for (const tabs of document.querySelectorAll('.nav.nav-tabs')) {
        tabs.querySelector('.nav-item button').click()
    }

    // If url has hash try to open collapse
    const fragment = window.location.hash.replace('#', '')
    if (fragment && document.getElementById(`${fragment}`)) {
        document.getElementById(`${fragment}`).click()
    }

    // Open navigation items in a new tab
    for (const link of document.querySelectorAll('.page-navigation a[href]')) {
        if (!link.href.includes(window.location.host)) {
            link.setAttribute('target', '_blank')
        }
    }

    commercialTable()

    // Function to update the counter for a specific time-counter element
    function updateCounter(timeCounterElement) {
        // Get the date from the "data-time-counter" attribute for the specific element
        const targetDate = new Date(timeCounterElement.getAttribute('data-time-counter')).getTime()

        // Get the current time
        const currentDate = new Date().getTime()

        // Calculate the remaining time in milliseconds
        const timeRemaining = targetDate - currentDate

        // Convert milliseconds to days, hours, minutes, and seconds
        const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24))
        const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000)

        const textDays = gettext('days')
        const textHours = gettext('hours')
        const textMinutes = gettext('minutes')
        const textSeconds = gettext('seconds')

        // Update the content of the specific element with the class "time-counter"
        timeCounterElement.innerHTML = `
            <div class="days">
                <div>${days}</div>
                <div>${textDays}</div>
            </div>
            <div class="hours">
                <div>${hours}</div>
                <div>${textHours}</div>
                </div>
            <div class="minutes">
                <div>${minutes}</div>
                <div>${textMinutes}</div>
            </div>
            <div class="seconds">
                <div>${seconds}</div>
                <div>${textSeconds}</div>
            </div>
        `
    }

    // Get all elements with the class "time-counter"
    const timeCounterElements = document.querySelectorAll('.time-counter')

    // Iterate over all found elements and call the updateCounter function for each of them
    for (const timeCounterElement of timeCounterElements) {
        updateCounter(timeCounterElement)
    }

    // Run the updateCounter function for each element every second
    setInterval(function() {
        for (const timeCounterElement of timeCounterElements) {
            updateCounter(timeCounterElement)
        }
    }, 1000)

    const structure = document.querySelector("a[href=?structure]")
    if (structure) {
        structure.addEventListener('click', () => {setTimeout(setCMSBarWidth, 1000)}, true)
    }
})

addEventListener("resize", commercialTable)
