const selector = '.commercial-services-row-height article'
let resizeTimer = null

/**
 * Set height for cell
 *
 * @param { NodeList } columns
 * @param { Array } resultArray
 */
const setHeight = (columns, resultArray) => {
    for (const column of columns) {
        let indexResultArray = 0
        for (const cell of column.querySelectorAll('aside')) {
            cell.style.height = `${resultArray[0][indexResultArray]}px`
            indexResultArray++
        }
    }
}

/**
 * Get result array
 *
 * @param { NodeList } columns
 * @param { Array } allArray
 */
const getResultArray = (columns, allArray) => {
    const resultArray = []
    allArray.forEach((outsideArr) => {
        outsideArr.forEach((insideValue, i) => {
            if (resultArray[i] < insideValue || resultArray[i] === undefined) {
                resultArray[i] = insideValue
            }
        })
    })
    setHeight(columns, resultArray)
}

/**
 *  Get all heights
 *
 *  @param { NodeList } columns
 */
const getAllHeights = (columns) => {
    const allArray = []
    let columnHeight = []
    for (const column of columns) {
        for (const cell of column.querySelectorAll('aside')) {
            columnHeight.push(cell.offsetHeight)
        }

        allArray.push([columnHeight])
        columnHeight = []

    }
    getResultArray(columns, allArray)
    resizeTimer = null
}

/**
 * Reset all heights
 */
const resetHeights = () => {
    for (const node of document.querySelectorAll(selector + ' aside')) {
        node.style.height = null
    }
}

export const commercialTable = () => {
    resetHeights()
    const columns = document.querySelectorAll(selector)
    if (columns.length) {
        if (resizeTimer) {
            clearTimeout(resizeTimer)
        }
        resizeTimer = setTimeout(() => {
            getAllHeights(columns)
        }, 500)
    }
}
